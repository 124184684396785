export default {
  okText: 'Bestätigen',
  closeText: 'Schließen',
  cancelText: 'Abbrechen',
  loadingText: 'Laden ...',
  saveText: 'Speichern',
  delText: 'Löschen',
  resetText: 'Zurücksetzen',
  searchText: 'Suchen',
  queryText: 'Jetzt überprüfen',
  nextText: 'Weiter',

  inputText: 'Bitte eingeben',
  chooseText: 'Bitte auswählen',

  add: 'Hinzufügen',
  update: 'Erneuern',
  refresh: 'Aktualisieren',
  back: 'Zurück',

  light: 'Helles Theme',
  dark: 'Dunkles Theme',
  countdown: {
    normalText: 'Verifizierungscode erhalten',
    sendText: ' Erneuter Erhalt nach {X} Sek.',
  },
};
